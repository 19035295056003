import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Header from '../components/Header';
import { makeTitle } from '../functions/Utils'
import RichContent from '../components/RichContent';
import Form from '../components/Form';

const ContactPage = ({ data }) => {
	const { contentfulPage } = data;
	const one = contentfulPage.content[0];
	const form = contentfulPage.content[1];

	return <Layout fullMenu>
		<SEO title={makeTitle(contentfulPage.name)} />
		<article id="main">
			<Header
				className="contact"
				image={contentfulPage.image.file.url}
			>
				<h2>{makeTitle(contentfulPage.name)}</h2>
				<p>{one.header}
					<br />
					{one.subHeader}</p>
			</Header>
			<section className="wrapper style5">
				<div className="row">
					<div className="col-8 col-12-small" style={{ margin: '0 auto' }}>
						<h2>{form.name}</h2>
						<RichContent content={form.description.json} />
						<Form form={form} />
					</div>
				</div>
			</section>
		</article>

		{/* Hidden forms for Netlify bots */}
		<form name="contact-me" data-netlify-honeypot="bot-field" action="POST" data-netlify="true" hidden>
			<input type="hidden" name="bot-field" />
			<input type="text" name="Name" />
			<input type="email" name="Email" />
			<select
				type="text"
				placeholder="General Enquiries"
				name="Subject"
			>
				<option value="General Enquiries"></option>
				<option value="Business Enquiries"></option>
				<option value="Manuscripts"></option>
				<option value="Ghost Writing"></option>
				<option value="Editing Services"></option>
			</select>
			<textarea name="Message" id="message" rows="6"></textarea>
		</form>
	</Layout>
}




export const query = graphql`
	query ContactPageQuery {
		contentfulPage(id: {eq: "60e455bd-d8bf-5f3a-a0df-0538b4486359"}) {
		id
		name
		content {
			... on ContentfulForm {
			id
			name
			description {
				json
			}
			contentfulfields {
				label
				placeholder
				type
				choices
			}
			submit {
				content
				linksTo
			  }
			}
			... on ContentfulSection {
			id
			name
			header
			subHeader
			}
		}
		image {
			file {
			url
			}
			title
		}
		}
	}
  `

export default ContactPage;
