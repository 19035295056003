import React from 'react'

export default function Form({form}) {
    const types = {
        "Short text": (props) =>  <div className="col-12 col-12-small">
        <input 
            type="text"
            name={`${props.label}`} 
            id="name" 
            defaultValue="" 
            placeholder={props.placeholder}
            />
            </div>,
        "Multiple choice": (props) => <div className="col-12">
            <select 
                name={`${props.label}`} 
                id="category"
                placeholder={props.placeholder}
                >
                { React.Children.toArray(props.choices.map(option => <option value={option}>{option}</option>))}
            </select>
        </div>,
        "Email": (props) => <div className="col-12">
            <input 
                type="email" 
                name={`${props.label}`} 
                id="email" 
                defaultValue="" 
                placeholder={props.placeholder} 
                />
            </div>,
        "Long text": (props) => <div className="col-12">
                <textarea name={props.label} id="message" placeholder={props.placeholder} rows="6"></textarea>
            </div>,
        "Check box": (props) => <div className="col-6 col-12-small">
        <input type="checkbox" id="human" name={props.label} defaultChecked />
        <label htmlFor={props.label}>{props.placeholder}</label>
    </div>,
        "Phone Number": (props) => <span>Not implemented yet</span>
    }

    const formComponents = form.contentfulfields.map(field => { 
        return {
            ...field,
            type: field.type[0]
        }
    })


    return (
        <form id="contact-me" action="/success" name="contact-me" method="POST">
            <input type="hidden" name="bot-field" /> 
            <input type="hidden" name="form-name" value="contact-me" />
            <div className="row gtr-uniform">
                { React.Children.toArray(formComponents.map(component => {
                    return types[component.type](component)
                }))}
                <div className="col-12">
                    <div ></div>
                    <ul className="actions">
                        <li><button type={form.submit.linksTo} className="primary" >{form.submit.content}</button></li>
                    </ul>
                </div>
            </div>
        </form>
    )
}
