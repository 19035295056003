import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


// const document = {
//   nodeType: 'document',
//   data: {},
//   content: [
//     {
//       nodeType: 'paragraph',
//       data: {},
//       content: [
//         {
//           nodeType: 'text',
//           value: 'Hello',
//           data: {},
//           marks: [{ type: 'bold' }]
//         },
//         {
//           nodeType: 'text',
//           value: ' world!',
//           data: {},
//           marks: [{ type: 'italic' }]
//         }
//       ]
//     }
//   ]
// };

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: text => `<b>${text}<b>`
//   },
//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (node, next) =>
//       `<custom-paragraph>${next(node.content)}</custom-paragraph>`
//   }
// };


const RichContent = ({content}) => {
    return (
        <>
            { documentToReactComponents(content) }
        </>
    )
}

RichContent.propTypes = {

}

export default RichContent
